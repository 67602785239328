/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

export function AsideMenuList({ layoutProps }) {

	const location = useLocation()
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
          'menu-item-active'} menu-item-open menu-item-not-hightlighted`
			: ''
	}

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				<li
					className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/dashboard">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
						</span>
						<span className="menu-text">Dashboard</span>
					</NavLink>
				</li>
				<li className="menu-section">
					<h4 className="menu-text">USERS</h4>
					<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
				</li>
				<li
					className={`menu-item ${getMenuItemActive('/admins', false)} ${getMenuItemActive('/edit-admin', false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/admins">
						<span className="menu-icon">
							<AssignmentIndIcon/>
						</span>
						<span className="menu-text">Administrators</span>
					</NavLink>
				</li>
			</ul>

			{/* end::Menu Nav */}
		</>
	)
}
