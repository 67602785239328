import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { ContentRoute } from '../_metronic/layout'
import { DashboardPage } from './pages/DashboardPage'
import AdminsPage from './modules/ContentManager/admins/AdminsPage'
import EditAdminsPage from './modules/ContentManager/admins/EditAdminsPage'

export default function BasePage() {
	return (
		<Switch>
			{/* Redirect from root URL to /dashboard. */ }
			<Redirect exact from="/" to="/dashboard" />
			<ContentRoute path="/dashboard" component={DashboardPage} />

			{/* Administrators */}
			<ContentRoute from="/admins" component={AdminsPage} />
			<ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />

			<Redirect to="/error" />
		</Switch>
	)
}
