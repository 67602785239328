import { authClient, API } from '../index'

// Get all users
export const getUsers = () => {
  return authClient().get(`${API}/user`)
}

// Get user by id
export const getUserById = (id) => {
  return authClient().get(`${API}/user/${id}`)
}

// Delete user
export const deleteUser = (id) => {
  return authClient().delete(`${API}/user/${id}`)
}

// Create user
export const postUser = async (user, file) => {
  return authClient().post(`${API}/user`, user)
}

// Update user
export const updateUser = async (id, user, file) => {
  return authClient().put(`${API}/user/${id}`, user)
}
