import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteUser, getUserById, postUser, updateUser } from '../../../../api/user'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { alertError, alertSuccess } from '../../../../utils/logger'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyAdmin() {
  return {
    fullName: '',
    email: '',
    role: 'admin',
    password: '',
    repeatPassword: ''
  }
}

export default function EditAdminsPage() {
  const [admin, setAdmin] = useState(getEmptyAdmin())
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const adminId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!adminId) {
      disableLoadingData()
      return
    }
    getUserById(adminId).then(res => {
      if (res.status === 200) {
        const user = res.data
        delete user.password
        setAdmin(user)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get admin.' })
      history.push('/admins')
    })
  }, [adminId, disableLoadingData, history])

  function saveAdmin() {
    if (!admin.password || !admin.repeatPassword) {
      alertError({ error: null, customMessage: 'Please enter the password.' })
      return
    }
    if (admin.password !== admin.repeatPassword) {
      alertError({ error: null, customMessage: 'Passwords do not match.' })
      return
    }
    delete admin.repeatPassword
    if (!adminId) {
      postUser(admin).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Admin successfully created.' })
          history.push('/admins')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save admin.' })
      })
    } else {
      updateUser(adminId, admin).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/admins')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setAdmin({ ...admin, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit admin'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Full name"
            value={admin.fullName}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`email`}
            label="Email"
            value={admin.email}
            onChange={handleChange('email')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`password`}
            label="Password"
            value={admin.password}
            onChange={handleChange('password')}
            InputLabelProps={{
              shrink: true
            }}
            type="password"
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`repeatPassword`}
            label="Repeat password"
            value={admin.repeatPassword}
            onChange={handleChange('repeatPassword')}
            InputLabelProps={{
              shrink: true
            }}
            type="password"
            margin="normal"
            variant="outlined"
            required
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/admins')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveAdmin()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save admin
      </Button>
      {adminId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete admin
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this admin?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteUser(adminId).then(res => {
              if (res.status === 204 || res.status === 200) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Admin deleted successfully' })
                history.push('/admins')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete admin.' })
            })
          }}
        />
      </>}
    </>
  );
}
